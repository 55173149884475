import React from "react"
import { Link } from "gatsby"
import {
  Section,
  H2Title,
  H3Title,
  Container,
  M,
  GrayBorder,
  OrangeSpan,
  BlogDoubleGrid,
  P,
  FlexCenter,
  Col,
  Output,
} from "../../../globalStyle"
import { VideoWrapper } from "./style"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import NextPreviousPager from "../../UI/NextPreviousPager"
import IDoubleProps from "./types"
import styled from "styled-components"
import useDoubleTemplate from "./useDoubleTemplate"
import Button from "../../UI/Button"

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 554px;
  & > img,
  div {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 780px) {
    height: 300px;
  }
`
export const SmallImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 128px;
  & > img {
    height: 100%;
  }

  & > div {
    width: 100%;
  }
`

export const TagsWrapper = styled.div`
  margin-bottom: 25px;
`

const DoubleTemplate = (props: IDoubleProps) => {
  const { prefixUrl, pageContext, pageData, restPageData } = props
  const content = pageData.content

  const { isHR } = useDoubleTemplate(pageData.tags)

  const extension = pageData.picture.file.fileName.slice(
    pageData.picture.file.fileName.length - 3,
    pageData.picture.file.fileName.length
  )

  let otherPostsExt = [""]
  let otherPosts = []

  for (let i = 0; i < restPageData.edges.length; i++) {
    otherPostsExt[i] = restPageData.edges[i].node.picture.file.fileName.slice(
      restPageData.edges[i].node.picture.file.fileName.length - 3,
      restPageData.edges[i].node.picture.file.fileName.length
    )
  }
  for (let i = 0; i < 7; i++) {
    otherPosts[i] = restPageData.edges[i]
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage
            alt="Contentful post image"
            style={{ width: "100%", height: "600px" }}
            image={node.data.target.gatsbyImageData}
          />
        )
      },
    },
  }
  const output = renderRichText(content, options)
  return (
    <Section style={{ marginTop: "80px" }}>
      <Container>
        <M>
          <H2Title> {pageData.title}</H2Title>
          {prefixUrl !== "products" && (
            <TagsWrapper>
              <OrangeSpan>{pageData.tags}</OrangeSpan>
            </TagsWrapper>
          )}
          <GrayBorder />
        </M>
      </Container>
      <ImageWrapper>
        {extension === "svg" ? (
          <img src={pageData.panoramaPicture.file.url} />
        ) : (
          <GatsbyImage
            imgStyle={{ objectFit: "contain" }}
            image={pageData.panoramaPicture.gatsbyImageData}
            alt={pageData.title}
          />
        )}
      </ImageWrapper>
      <Container>
        <M>
          <BlogDoubleGrid>
            <div>
              <FlexCenter>
                <M>
                  <P>{pageData.shortDescription.shortDescription}</P>
                  {pageData.videoSlug && (
                    <VideoWrapper>
                      <iframe
                        width="100%"
                        height="550px"
                        src={pageData.videoSlug}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </VideoWrapper>
                  )}
                </M>
              </FlexCenter>

              <article>
                <Output>{output}</Output>
                {isHR === true && (
                  <>
                    <P>
                      Are you interested in working with Oakfusion? Press the
                      button below and apply.
                    </P>
                    <Button slug="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=cdf7c9be45f246f0a710c7a25fff4b90">
                      Apply now
                    </Button>
                  </>
                )}
              </article>
            </div>

            <Col style={{ alignItems: "center" }}>
              <M>
                <FlexCenter>
                  <P>ABOUT AUTHOR</P>
                  <GatsbyImage
                    alt={pageData.title}
                    style={{ width: "128px", height: "128px" }}
                    image={pageData.author.image.gatsbyImageData}
                  />
                  <M>
                    <H3Title isDarkBlue={true} center={true}>
                      {pageData.author.name}
                    </H3Title>
                  </M>
                </FlexCenter>
                <FlexCenter style={{ marginTop: "50px" }}>
                  <GrayBorder />
                  <div>OTHER POSTS</div>
                </FlexCenter>
                {restPageData.edges.map((p, idx: number) =>
                  idx < 5 && p.node.title === pageData.title ? (
                    <div key={idx}></div>
                  ) : (
                    <div key={idx}>
                      <Link to={`/${prefixUrl}/` + p.node.slug}>
                        <FlexCenter>
                          <SmallImageWrapper>
                            {otherPostsExt[idx] === "svg" ? (
                              <img src={p.node.picture.file.url} />
                            ) : (
                              <GatsbyImage
                                imgStyle={{
                                  objectFit: "contain",
                                  width: "100%",
                                  height: "100%",
                                }}
                                image={p.node.picture.gatsbyImageData}
                                alt={p.node.title}
                              />
                            )}
                          </SmallImageWrapper>
                          <H3Title isDarkBlue={true} center={true}>
                            {p.node.title}{" "}
                          </H3Title>

                          <GrayBorder />
                        </FlexCenter>
                      </Link>
                    </div>
                  )
                )}
              </M>
            </Col>
          </BlogDoubleGrid>
          <br />
          <GrayBorder />
          <NextPreviousPager prefixUrl={prefixUrl} pageContext={pageContext} />
          <br />
        </M>
      </Container>
    </Section>
  )
}

export default DoubleTemplate
