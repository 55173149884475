import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import { graphql } from "gatsby"

import DoubleTemplate from "../components/Sections/DoubleTemplate"

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      shortDescription {
        shortDescription
      }
      picture {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        file {
          fileName
          url
        }
      }
      panoramaPicture {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        file {
          fileName
          url
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      keywords
      metaDescription
      title
      slug
      tags
      author {
        image {
          gatsbyImageData
        }
        name
      }
    }
    allContentfulBlogPost(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          picture {
            gatsbyImageData(layout: FULL_WIDTH)
            title
            file {
              fileName
              url
            }
          }
          shortDescription {
            shortDescription
          }
          title
          slug
        }
      }
    }
  }
`

const BlogTemplate = props => {
  return (
    <Layout>
      <Head
        description={props.data.contentfulBlogPost.metaDescription}
        pageTitle={props.data.contentfulBlogPost.title}
        keywords={props.data.contentfulBlogPost.keywords}
      />
      <DoubleTemplate
        pageData={props.data.contentfulBlogPost}
        restPageData={props.data.allContentfulBlogPost}
        pageContext={props.pageContext}
        prefixUrl="blog"
      />
    </Layout>
  )
}

export default BlogTemplate
