import { useState } from "react"
import { useEffect } from "react"

const useDoubleTemplate = tags => {
  const [isHR, setIsHR] = useState(false)

  const checkIfIsHRPost = () => {
    let tagsArray = tags.split(",")
    let lowerCaseTags = tagsArray.map(tag => tag.toLowerCase())
    lowerCaseTags.forEach(tag => tag.includes("hr") && setIsHR(true))
  }

  useEffect(checkIfIsHRPost)

  return { isHR }
}

export default useDoubleTemplate
